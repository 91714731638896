import React from "react"
import styled from "@emotion/styled"
import { ArrowRight } from "./styledComponents"
import { MarkdownContent } from "./styledComponents"

const Title = styled.a`
  font-size: 24px;
  font-weight: bold;
  display: block;
  color: ${props => props.theme.primaryColor};

  &:hover {
    text-decoration: none;
  }
`

const Excerpt = styled(MarkdownContent)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9; /* number of lines to show */
  line-height: 20px !important; /* fallback */
  max-height: 180px;
  margin-bottom: 15px;

  & > p {
    font-size: 14px !important;
    font-weight: normal;
    line-height: 20px !important;
    margin-top: 0;
    margin-bottom: 10px;
  }

  & h1 {
    font-size: 1.1em;
    line-height: normal;
  }

  & h2 {
    font-size: 20px;
    line-height: 24px;
  }

  & h2:first-of-type {
    margin-top: 0;
  }
`

const StyledLink = styled.a`
  font-size: 14px;
  font-weight: 600;
  color: #000;

  background: #fff;
  display: inline-block;
  padding: 10px 20px;
  border-radius: 50px;

  display: inline-flex;
  align-items: center;
  border: 1px solid #dfdfdf;
`

export default props => {
  const { page, pageContext } = props
  const { site } = pageContext
  const { content = "" } = page

  const readMore = site["read-more"] || "Read more"

  return (
    <div className={props.className}>
      <Title href={page.path} className="mb-1">
        {page.title}
      </Title>

      <Excerpt
        dangerouslySetInnerHTML={{ __html: content }}
        maxHeight={"180px"}
        isExcerpt={true}
      />

      <StyledLink href={page.path}>
        {readMore}
        <ArrowRight />
      </StyledLink>
    </div>
  )
}
