import React from "react"
import styled from "@emotion/styled"
import { Row } from "react-flexbox-grid"

const Container = styled.div`
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
`

const Link = styled.a`
  color: #333;
`

const ListItem = styled.li`
  list-style-position: outside;
`

export default props => {
  const { pageContext = {} } = props
  const { modules = {} } = pageContext
  const { htmlSitemap = {} } = modules
  const groups = [{ id: "providers", title: "Reviews" }, { id: "posts", title: "Posts" }, { id: "pages", title: "Pages", checkVars: true }, { id: "products", title: "Slots" }, { id: "taxonomies", title: "Taxonomies" }, { id: "filterTaxonomies" }]

  function title(item, checkVars = false) {
    if (!checkVars) {
      return item?.title || item?.name
    }

    return (item?.title || item?.name || "")?.replace("%COUNT", "")?.replace("[%s:count]", "")?.replace("%YEAR", "")
  }

  return <Container>
    {groups.map(g => {

      if (!htmlSitemap?.[g?.id]?.length) return null

      return (
        <React.Fragment>
          { g.title ? <h3 className="mt-5">{g.title}</h3> : null}
          <Row className="">
            {htmlSitemap?.[g?.id]?.map(p => {
              return (<ul xs={4} md={3} className="col-xs-4 col-md-3"><ListItem><Link href={p.path}>{title(p, g?.checkVars)}</Link></ListItem></ul>)
            })}
          </Row>
        </React.Fragment>
      )
    })}
  </Container>
}
