import React from "react"
import Layout from "../../components/layout"
import { trackWindowScroll } from "react-lazy-load-image-component"
import { Grid, Row, Col } from "react-flexbox-grid"
import { HeroSection, MarkdownContent, StyledSection } from "../../components/styledComponents"
import ProviderList from "../../components/providerList"
import ChildPageListItem from "../../components/childPageListItem"
import Faq from "../../components/faq"
import Breadcrumbs from "../../components/breadcrumbs"
import HtmlSitemap from "../../components/htmlSitemap"

const Page = props => {
  const { pageContext, scrollPosition } = props
  const { page = {}, modules = {} } = pageContext
  const { properties = {} } = page
  const { template = {} } = properties
  const { data: templateData = {} } = template
  const { value: templateName = "" } = templateData
  const { allProviders = [], featuredProviders, childPages, htmlSitemap } = modules
  const hideWidgets = templateName?.toLowerCase() === "basic" || templateName?.toLowerCase() === "allproviders"

  const fullWidth = allProviders?.length

  return (
    <Layout
      {...props}
      headerBackground={"#fff"}
      footerLight
      htmlTitle={page["html-title"] || page.title}
      metaDescription={page["meta-description"] || ""}
      path={htmlSitemap ? "/sitemap/" : page.path}
      deepLinks={true}
      scrollPosition={scrollPosition}
    >
      <HeroSection>
        <Grid>
          <Breadcrumbs {...props} invert={1} />
        </Grid>
      </HeroSection>

      <StyledSection className="py-5">
        <Grid>
          <Row>
            <Col xs={12} md={fullWidth ? 12 : 9} mdOffset={fullWidth ? 0:1}>
              <h1 className="my-0">{page.title}</h1>
              {htmlSitemap ? <HtmlSitemap {...props} /> : null}
            </Col>
            {childPages?.length ? (
              <Col xs={12} md={9} mdOffset={1} className="my-3">
                <Row>
                  {childPages.map((childPage, i) => {
                    return (
                      <ChildPageListItem
                        {...props}
                        page={childPage}
                        key={i}
                        className="col-12 col-md-3 my-3"
                      />
                    )
                  })}
                </Row>
              </Col>
            ) : null}
            {!hideWidgets && featuredProviders?.length ? (
              <Col xs={12} md={9} mdOffset={1} className="my-3">
                <ProviderList
                  {...props}
                  providers={featuredProviders}
                  count={3}
                  dynamic
                  resourceId={page?.allanId}
                />
              </Col>
            ) :
              null}
            {/*
            <FeaturedImage
              src={
                post.properties?.["featured-image"] &&
                post.properties?.["featured-image"].resolved[0].url
              }
            />
            */}
            <Col xs={12} md={9} mdOffset={1}>
              {page?.content && (
                <MarkdownContent
                  dangerouslySetInnerHTML={{ __html: page?.content }}
                />
              )}
            </Col>

            {allProviders?.length ? (
              <Col xs={12} md={12} mdOffset={0} className="my-3">
                <ProviderList
                  {...props}
                  providers={allProviders}
                  count={1000}
                  maxItemsToShow={1000}
                  resourceId={page?.allanId}
                />
              </Col>
            ) :
              null}
          </Row>
        </Grid>
      </StyledSection>

      {(page?.faq && (
        <StyledSection className="pt-0">
          <Grid>
            <Col xs={12} md={9} mdOffset={1}>
              <Faq content={page.faq} />
            </Col>
          </Grid>
        </StyledSection>
      )) ||
        null}
    </Layout>
  )
}

export default trackWindowScroll(Page)
